import { ProductProjection } from '@commercetools/platform-sdk'

import { getProductName } from 'commercetools/utils/product/getProductName'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { events } from 'gtm/events'
import { gtagEvent } from 'gtm/gtag'
import { formatDecimalPrice } from 'utils/formatDecimalPrice'

export const trackViewProductsList = (
  products: ProductProjection[] | undefined,
  lang: string,
  item_list_name: string | undefined,
) => {
  const lineItems = products?.map((product) => ({
    item_id: getProductVariant(product)?.sku,
    item_name: getProductName(product, lang),
    quantity: 1,
    price: formatDecimalPrice(
      getProductVariant(product)?.price?.value?.centAmount,
      getProductVariant(product)?.price?.value?.fractionDigits,
    ),
  }))

  gtagEvent({
    event: events.viewItemList,
    ecommerce: {
      item_list_name,
      items: lineItems,
    },
  })
}
