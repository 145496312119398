import { Box, BoxProps, Flex, SystemStyleInterpolation, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { ProductProjection } from '@commercetools/platform-sdk'
import router from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useCart } from 'commercetools/hooks/use-cart'
import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { getAttributeValue } from 'commercetools/utils/product/getAttributeValue'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { ErrorAlert } from 'components/elements/ErrorAlert/ErrorAlert'
import { Availability } from 'components/modules/Availability/Availability'
import { OrderTemplateButtonVariants, OrderTemplateButton } from 'components/modules/OrderTemplate/OrderTemplateButton'
import { Price } from 'components/modules/Price/Price'
import { ProductsTableNameCell } from 'components/modules/Product/ProductsTable/ProductsTableNameCell'
import { QuantityInput } from 'components/modules/QuantityInput/QuantityInput'
import { arrayHasValue } from 'utils/arrayHasValue'

type Props = BoxProps & {
  products: ProductProjection[] | undefined
  priceTitle?: string
  variant?: string
  orderTemplateButtonVariant?: OrderTemplateButtonVariants
}

const TAG = 'ProductsTable'

const styles = {
  wrapper: {
    width: 'full',
    mt: { base: '2', md: '0' },
    position: { base: 'relative', md: 'static' },
    overflow: { base: 'auto', md: 'inherit' },
    whiteSpace: { base: 'nowrap', md: 'normal' },
  },
} as const satisfies SystemStyleInterpolation

export function ProductsTable({
  products,
  priceTitle,
  variant = 'responsive',
  orderTemplateButtonVariant = 'icon',
  ...props
}: Props) {
  const [, , , getLineItemIfExists] = useCart()
  const { t, lang } = useTranslation('common')
  const redirect = (product: ProductProjection) => {
    const slug = getLocalizedString(product?.slug, lang) || product?.slug?.en
    router.push(`/product/${slug}`)
  }

  const productsWithMatchingVariants = products?.map((product) => ({
    matchingVariant: getProductVariant(product),
    ...product,
  }))

  return (
    <Box data-testid={TAG} {...styles.wrapper} {...props}>
      {arrayHasValue(products) ? (
        <Table variant={variant}>
          <Thead fontSize="xs" p={0}>
            <Tr h={6}>
              <Th width="5%" pt={0} />
              <Th width="15%" textTransform="none" pt={0}>
                <Text>{t('part-no')}</Text>
              </Th>
              <Th width="30%" textTransform="none" pt={0}>
                <Text>{t('name')}</Text>
              </Th>
              <Th width="25%" textTransform="none" pt={0}>
                <Text>{t('availability')}</Text>
              </Th>
              <Th width="10%" textTransform="none" pt={0} whiteSpace="nowrap">
                <Text>{priceTitle ? priceTitle : t('net-price')}</Text>
              </Th>
              <Th width="15%" />
            </Tr>
          </Thead>
          <Tbody>
            {productsWithMatchingVariants?.map((product) => (
              <Tr key={product.id} h={20}>
                <Td zIndex={1} px={0}>
                  <OrderTemplateButton product={product} variant={orderTemplateButtonVariant} />
                </Td>
                <Td pr={0}>
                  <Text cursor="pointer" onClick={() => redirect(product)}>
                    {getAttributeValue(product?.matchingVariant?.attributes, 'itemNumber')}
                  </Text>
                </Td>
                <Td pr={0}>
                  <ProductsTableNameCell product={product} lang={lang} />
                </Td>
                <Td pr={0}>
                  <Availability
                    partNumber={getAttributeValue(product?.matchingVariant?.attributes, 'itemNumber')}
                    availability={product?.matchingVariant?.availability}
                  />
                </Td>
                <Td pr={0}>
                  <Price price={product?.standalonePrice?.value} />
                </Td>
                <Td pr={0}>
                  <Flex width="full" textAlign="end" justifyContent="end">
                    <QuantityInput product={product} lineItem={getLineItemIfExists(product)} />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <ErrorAlert>{t('couldnt-find-any-items')}</ErrorAlert>
      )}
    </Box>
  )
}
