import { ErrorObject, OrderPagedQueryResponse } from '@commercetools/platform-sdk'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { fetchBuOrdersAsAssociate } from 'commercetools/api/order'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { OrderQueryParams } from 'commercetools/types'

export function useOrdersB2BAsAssociate(
  params: OrderQueryParams,
  config?: UseQueryOptions<OrderPagedQueryResponse, ErrorObject>,
): UseQueryResult<OrderPagedQueryResponse, ErrorObject> {
  const { search, from, to, shippingAddress, page = 1, limit = 15, sort = 'createdAt desc', withTotal } = params

  const businessUnit = useBusinessUnit()
  const [customer] = useCustomer()
  const associateId = customer?.data?.id || ''

  return useQuery<OrderPagedQueryResponse, ErrorObject, OrderPagedQueryResponse>(
    [
      'orders-b2b-as-associate',
      search,
      from,
      to,
      shippingAddress,
      page,
      sort,
      limit,
      businessUnit.id,
      associateId,
      withTotal,
    ],
    () => fetchBuOrdersAsAssociate({ ...params, businessUnitKey: businessUnit.key, associateId: associateId }),
    {
      enabled: !!businessUnit.key && !!businessUnit.storeKey,
      ...config,
    },
  )
}
