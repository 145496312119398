import { ErrorObject } from '@commercetools/platform-sdk'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { fetchRecentlyBoughtItemsSkusGraphQl } from 'commercetools/api/order'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { RecentlyBoughtItemsSkusResponse } from 'commercetools/types'

export function useRecentlyBoughtItemsSkus(
  params: { sort?: string; limit?: number } = {},
  config?: UseQueryOptions<RecentlyBoughtItemsSkusResponse, ErrorObject>,
): UseQueryResult<RecentlyBoughtItemsSkusResponse, ErrorObject> {
  const { sort = 'createdAt desc', limit = 15 } = params

  const businessUnit = useBusinessUnit()
  const [customer] = useCustomer()
  const associateId = customer?.data?.id || ''

  return useQuery<RecentlyBoughtItemsSkusResponse, ErrorObject, RecentlyBoughtItemsSkusResponse>(
    ['recently-bought-items-skus', businessUnit.id, businessUnit.storeKey, associateId],
    () =>
      fetchRecentlyBoughtItemsSkusGraphQl({
        businessUnitKey: businessUnit.key,
        storeKeys: businessUnit.storeKey,
        customerId: associateId,
        limit,
        sort,
      }),
    {
      enabled: !!businessUnit.key && !!businessUnit.storeKey && !!associateId,
      ...config,
    },
  )
}
