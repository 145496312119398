import { Flex, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'
import { ProductProjection } from '@commercetools/platform-sdk'
import truncate from 'lodash/truncate'
import NextLink from 'next/link'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { getProductName } from 'commercetools/utils/product/getProductName'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { getProductVariantDescription } from 'commercetools/utils/product/getProductVariantDescription'

const NAME_LENGTH_LIMIT = 38
const DESCRIPTION_LENGTH_LIMIT = 50

const styles = {
  flexWrapper: { direction: 'column', textAlign: 'start' },
  popover: { fontSize: 'xs', bg: 'primary.black', color: 'primary.white' },
  name: { fontWeight: 'bold', textTransform: 'uppercase', cursor: 'pointer', color: 'currentColor' },
  description: { lineHeight: 4, fontSize: 'xs' },
} as const

type Props = {
  product: ProductProjection
  lang: string
}

export function ProductsTableNameCell({ product, lang }: Props) {
  const productVariant = getProductVariant(product)
  const name = getProductName(product, lang)
  const description = getProductVariantDescription(name, productVariant, lang)
  const showPopover = name.length > NAME_LENGTH_LIMIT || (description?.length || 0) > DESCRIPTION_LENGTH_LIMIT

  return (
    <Popover variant="round" closeOnBlur={true} trigger="hover">
      <PopoverTrigger>
        <Flex {...styles.flexWrapper}>
          <Link
            {...styles.name}
            as={NextLink}
            href={`/product/${getLocalizedString(product?.slug, lang)}`}
            rel="noopener noreferrer"
          >
            {truncate(name, { length: NAME_LENGTH_LIMIT, omission: '...' })}
          </Link>

          {description && (
            <Text {...styles.description}>
              {truncate(description, { length: DESCRIPTION_LENGTH_LIMIT, omission: '...' })}
            </Text>
          )}
        </Flex>
      </PopoverTrigger>

      <PopoverContent>
        {showPopover ? (
          <PopoverBody {...styles.popover}>
            <Text {...styles.name}>{name}</Text>
            {description && <Text {...styles.description}>{description}</Text>}
          </PopoverBody>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
